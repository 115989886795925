// FleetEdgeLand.js
import React, { useState } from 'react';
import './FleetEdgeLand.css'; // Import the specific CSS file for FleetEdgeLand

function FleetEdgeLand() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="fleet-edge-land">
      {/* Introduction/Overview Section */}
      <section className="fleet-edge-land-overview">
        <div className="fleet-edge-land-header">
          <h1>LandEdge</h1>
          <p>LandEdge provides cutting-edge land-based solutions for government operations. With advanced Kubernetes management, it ensures secure data processing and communication capabilities, making it ideal for land-based surveillance, monitoring, and response activities.</p>
        </div>
        <div className="fleet-edge-land-image-logo">
          <img src="/images/fleetedgeLand/png/GovEdge - LandEdge3.png" alt="FleetEdge Land Product" />
        </div>
      </section>

      {/* First Div Below (Detailed Explanation and Image) */}
      <div className="fleet-edge-land-detailed-section">
        {/* Detailed Explanation */}
        <div className="fleet-edge-land-explanation">
          <h2>LandEdge</h2>
          <p>LandEdge offers a robust platform for deploying Kubernetes clusters in land-based environments. It is designed to provide high performance and reliability for government land operations, ensuring seamless data handling and communication for mission-critical tasks on the ground.</p>
        </div>

        {/* Image */}
        <div className="fleet-edge-land-image">
          <img
            src="/images/DHI-Diagram3.png"
            alt="FleetEdge Land Diagram"
            onClick={openModal}
          />
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the same image at a larger scale */}
            <img
              src="/images/DHI-Diagram3.png"
              alt="FleetEdge Land Diagram"
              className="modal-image"
            />
          </div>
        </div>
      )}

      {/* Second Div Below (Benefits Section) */}
      <div className="fleet-edge-land-benefits-section">
        <h2>Benefits</h2>
        <div className="fleet-edge-land-benefits-list">
          <div className="benefit-icon">
            <img src="/images/shield-art.png" alt="Benefit 1" />
            <h3>Enhanced Security and Compliance</h3>
            <p>LandEdge automates security measures and ensures compliance with government standards, enhancing the security of your land-based operations.</p>
          </div>
          <div className="benefit-icon">
            <img src="/images/lock-art.png" alt="Benefit 2" />
            <h3>Simplified Management and Maintenance</h3>
            <p>Managing Kubernetes clusters becomes straightforward with LandEdge, reducing complexity and ongoing maintenance tasks.</p>
          </div>
          <div className="benefit-icon">
            <img src="/images/revenue-art.png" alt="Benefit 3" />
            <h3>Efficiency and Scalability</h3>
            <p>LandEdge simplifies cluster setup, saving time and effort, and can easily scale to meet the growing needs of your land-based operations.</p>
          </div>
        </div>
      </div>
      <div className="FE-features-div">
        <h2 className="FE-features-header">Features</h2>
        <div className="FE-row">
          {/* LandEdge for Ground Operations */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">LandEdge for Ground Operations</div>
              <p>
                Optimized for ground-based operations, LandEdge excels across various terrains, providing adaptability for Marine Corps and Army applications.
              </p>
            </div>
          </a>

          {/* Advanced Kubernetes Management */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">Advanced Kubernetes Management</div>
              <p>
                Leveraging the Federal Frontier Kubernetes Platform, LandEdge simplifies the management of Kubernetes clusters, enhancing operational efficiency in ground-based scenarios.
              </p>
            </div>
          </a>

          {/* Zero Trust Architecture */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">Zero Trust Architecture</div>
              <p>
                Implementing advanced security protocols including Calico CNI and Linkerd service mesh, LandEdge ensures robust security and data protection in diverse ground environments.
              </p>
            </div>
          </a>

          {/* Embedded Storage & ARM64 Processing */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">Embedded Storage & ARM64 Processing</div>
              <p>
                Empowered by NVMe/TCP storage solutions and ARM64 architecture, LandEdge offers optimal processing power and Size, Weight, and Power (SWaP) dynamics for ground-based applications.
              </p>
            </div>
          </a>
        </div>
      </div>

      <div className="FE-technical-specifications">
        <h2 className="FE-technical-specifications-header">Technical Specifications</h2>
        <p className="FE-technical-specifications-paragraph">
          LandEdge, optimized for ground operations, incorporates the NXP Layerscape LX2160a ARM64 SoC, ensuring superior performance across various terrains. The ARM64 architecture, known for its balance of performance and power, contributes to Size, Weight, and Power (SWaP) dynamics, making LandEdge suitable for a range of environmental conditions. Equipped with NVMe/TCP storage solutions from Lightbits Labs, LandEdge ensures robust data security. Technical specifications include temperature resilience, allowing it to operate efficiently in both extreme heat and cold. The architecture supports advanced security measures, including Calico CNI and Linkerd service mesh, ensuring secure and reliable ground operations.
          Furthermore, LandEdge seamlessly integrates with the Federal Frontier Kubernetes Platform, streamlining Kubernetes cluster management and enhancing operational efficiency. The adoption of a Zero Trust Architecture, along with advanced security protocols such as Calico CNI and Linkerd service mesh, ensures robust security and data protection for LandEdge deployments. The embedded storage and ARM64 processing, powered by NVMe/TCP storage solutions and ARM64 architecture, provide optimal performance and Size, Weight, and Power (SWaP) dynamics, making LandEdge a versatile solution for ground-based environments.
        </p>
      </div>


      <div className="learn-more">
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-learn-more-button" target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
        <a href="https://eupraxialabs.com/images/datasheet.pdf" target="_blank" className="FE-learn-more-button">
          View Datasheet
        </a>
        <a href="/contact" className="FE-learn-more-button">
          Request a Demo
        </a>
        <a href="/contact" className="FE-learn-more-button">
          Contact us for a Quote
        </a>
      </div>
    </div>
  );
}

export default FleetEdgeLand;
