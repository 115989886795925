/* Import required Font Awesome packages */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCog, faShieldAlt, faLink, faUsers, faUsersCog, faKey, faCogs, faUser, faPaintBrush, faUserCheck, faClock, faMap, faBan, faGlobe, faCode, faLanguage } from '@fortawesome/free-solid-svg-icons';
import './XtremeCloudSSO.css'; // Make sure the CSS file path is correct

function XtremeCloudSSO() {
  return (
    <div className="xtreme-cloud-sso">
       <div className="SSO-content">
        <div className="SSO-text">
          <h1>Modernized Single Sign-On for Today's Needs</h1>
          <h2>
            Feature-rich, cost-effective, and easy-to-deploy multi-cluster Kubernetes solution that leverages open-source innovations while avoiding vendor lock-in.
          </h2>
          <p>
            Our multi-cloud applications eliminate Disaster Recover/Continuity of Operations.
          </p>
          <p>
            Cloud service providers (CSP) compete for your workload.
          </p>
          <a href="http://readthedocs.eupraxia.io/docs/xtremecloud-sso/xtremecloud-single-sign-on/" className="learn-more-buttonSSO">Learn More</a>
          <a href="/contact" className="learn-more-buttonSSO">Contact us for a Quote</a>
        </div>
        <div className="SSO-image">
          <img src="/images/XCSSO-logo-1-transparent.png" alt="FKG Kubernetes Grid" />
        </div>
      </div>
       {/* Multi-Cloud vs. DR/COOP Section */}
      <div className="multi-cloud-vs-drcoop">
        <h2>Unlock the Power of Active/Active Multi-Cloud</h2>
        <p>Experience agility, resilience, and unmatched scalability with multi-cloud solutions.</p>
        <p>Modernize your operations and stay ahead of the competition by harnessing the potential of multi-cloud.</p>
        <p>Make the switch today for a brighter digital future.</p>
      </div>

      {/* Side-by-Side Comparison Section */}
      <div className="comparison">
        <div className="comparison-item">
          <h3>XtremeCloud SSO</h3>
          <ul>
            <li>Zero Downtime</li>
            <li>Scalability on Demand</li>
            <li>Vendor Diversification</li>
            <li>Geographic Distribution</li>
            <li>No Cloud Service Provider Lockin</li>
          </ul>
        </div>
        <div className="comparison-item">
          <h3>Other Solutions</h3>
          <ul>
            <li>Uncertainty of Data Backup and Recovery</li>
            <li>Potentially hours for disaster reponse</li>
            <li>Unacceptable Downtime</li>
            <li>Locked into raising prices of Cloud Service Providers</li>
          </ul>
        </div>
      </div>
      {/* Cloud Service Providers Competition */}
      <div className="cloud-service-providers">
        <div className="cloud-service-providers-content">
          <div className="cloud-service-providers-text">
            <h2>Cloud Service Providers will Compete for Your Business</h2>
            <p>Multi-cloud adoption puts you in a powerful position. Here's why and how cloud service providers will compete for your business:</p>
          </div>
          <div className="cloud-service-providers-list">
            <ul>
              <li><strong>Cost-Efficiency:</strong> With multiple cloud providers, you can choose the most cost-effective services for your needs, compelling providers to offer competitive pricing.</li>
              <li><strong>Improved Service Quality:</strong> Providers strive to deliver better services to win your business, leading to improved service quality and innovation.</li>
              <li><strong>Eliminates Vendor Lock-In:</strong> Multi-cloud reduces vendor lock-in, allowing you to switch providers easily. This forces providers to offer superior services to retain customers.</li>
              <li><strong>Innovation:</strong> Competition drives innovation. Providers innovate to offer new features and technologies to attract your workloads.</li>
            </ul>
          </div>
        </div>
      </div>


      {/* Key Features Section */}
      <div className="key-features">
        {/* Feature Card 1: Single Sign-On (SSO) for browser applications */}
        <div className="feature-card">
          <div className="feature-icon">
            <FontAwesomeIcon icon={faLock} />
          </div>
          <div className="feature-details">
            <h3>Easy SSO</h3>
            <p>Simplify web application authentication with Single Sign-On (SSO).</p>
          </div>
        </div>

        {/* Feature Card 2: OpenID Connect (OIDC) and OAuth 2.0 support */}
        <div className="feature-card">
          <div className="feature-icon">
            <FontAwesomeIcon icon={faCog} />
          </div>
          <div className="feature-details">
            <h3>OIDC & OAuth</h3>
            <p>Secure authorization and authentication with OIDC and OAuth 2.0.</p>
          </div>
        </div>

        {/* Feature Card 3: SAML2 support */}
        <div className="feature-card">
          <div className="feature-icon">
            <FontAwesomeIcon icon={faShieldAlt} />
          </div>
          <div className="feature-details">
            <h3>SAML2 Security</h3>
            <p>Enhanced security and federated authentication using SAML2.</p>
          </div>
        </div>

        {/* Feature Card 4: Identity Brokering: Authenticate with external OIDC or SAML Identity Providers */}
        <div className="feature-card">
          <div className="feature-icon">
            <FontAwesomeIcon icon={faLink} />
          </div>
          <div className="feature-details">
            <h3>Identity Brokering</h3>
            <p>Streamlined user authentication with external OIDC or SAML Identity Providers.</p>
          </div>
        </div>

        {/* Feature Card 5: Social Login: Enable login with Google, GitHub, Facebook, Twitter, and other social networks */}
        <div className="feature-card">
          <div className="feature-icon">
            <FontAwesomeIcon icon={faUsers} />
          </div>
          <div className="feature-details">
            <h3>Social Login</h3>
            <p>Convenient authentication with social media accounts.</p>
          </div>
        </div>

        {/* Feature Card 6: User Federation: Sync users from LDAP servers and Microsoft Active Directory */}
        <div className="feature-card">
          <div className="feature-icon">
            <FontAwesomeIcon icon={faUsersCog} />
          </div>
          <div className="feature-details">
            <h3>User Federation</h3>
            <p>Unified user management through LDAP servers and Microsoft Active Directory synchronization.</p>
          </div>
        </div>

        {/* Feature Card 7: Kerberos Bridge: Automatically authenticate users logged into a Kerberos server */}
        <div className="feature-card">
          <div className="feature-icon">
            <FontAwesomeIcon icon={faKey} />
          </div>
          <div className="feature-details">
            <h3>Kerberos Bridge</h3>
            <p>Efficient authentication for Kerberos server users without additional credentials.</p>
          </div>
        </div>

        {/* Feature Card 8: Admin Console: Central management of users, roles, clients, and configuration */}
        <div className="feature-card">
          <div className="feature-icon">
            <FontAwesomeIcon icon={faCogs} />
          </div>
          <div className="feature-details">
            <h3>Admin Console</h3>
            <p>Full control over user management, roles, clients, and server configuration.</p>
          </div>
        </div>

        {/* Feature Card 9: Account Management: Users can centrally manage their accounts */}
        <div className="feature-card">
          <div className="feature-icon">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="feature-details">
            <h3>Account Management</h3>
            <p>Empowered users can manage their accounts, reducing support requests.</p>
          </div>
        </div>

        {/* Feature Card 10: Theme Support: Customize user-facing pages to match your branding */}
        <div className="feature-card">
          <div className="feature-details">
            <h3>Theme Support</h3>
            <p>Personalize user-facing pages to align with your brand identity.</p>
          </div>
          <div className="feature-icon">
            <FontAwesomeIcon icon={faPaintBrush} />
          </div>
        </div>

        {/* Feature Card 11: Two-factor Authentication: Support for TOTP/HOTP via Google Authenticator or FreeOTP */}
        <div className="feature-card">
          <div className="feature-details">
            <h3>Two-factor Authentication</h3>
            <p>Enhanced security with TOTP/HOTP for two-factor authentication.</p>
          </div>
          <div className="feature-icon">
            <FontAwesomeIcon icon={faKey} />
          </div>
        </div>

        {/* Feature Card 12: Login Flows: Optional user self-registration, password recovery, email verification, etc. */}
        <div className="feature-card">
          <div className="feature-details">
            <h3>Login Flows</h3>
            <p>Configurable login flows, including self-registration and email verification.</p>
          </div>
          <div className="feature-icon">
            <FontAwesomeIcon icon={faUserCheck} />
          </div>
        </div>

        {/* Feature Card 13: Session Management: Admins and users can view and manage user sessions */}
        <div className="feature-card">
          <div className="feature-details">
            <h3>Session Management</h3>
            <p>Monitoring and management of user sessions for administrators and users.</p>
          </div>
          <div className="feature-icon">
            <FontAwesomeIcon icon={faClock} />
          </div>
        </div>

        {/* Feature Card 14: Geolocation Restrictions: Restrict access based on user location */}
        <div className="feature-card">
          <div className="feature-details">
            <h3>Geolocation Restrictions</h3>
            <p>Control access based on user geographical location.</p>
          </div>
          <div className="feature-icon">
            <FontAwesomeIcon icon={faMap} />
          </div>
        </div>

        {/* Feature Card 15: Account Lockout Policies: Prevent unauthorized access through failed login attempts */}
        <div className="feature-card">
          <div className="feature-details">
            <h3>Account Lockout Policies</h3>
            <p>Enhance security with account lockout policies triggered by failed login attempts.</p>
          </div>
          <div className="feature-icon">
            <FontAwesomeIcon icon={faBan} />
          </div>
        </div>

        {/* Feature Card 16: Multilingual Support: Available in multiple languages for a global user base */}
        <div className="feature-card">
          <div className="feature-details">
            <h3>Multilingual Support</h3>
            <p>Support for multiple languages to cater to a global user base.</p>
          </div>
          <div className="feature-icon">
            <FontAwesomeIcon icon={faGlobe} />
          </div>
        </div>

        {/* Feature Card 17: Customizable with Themes and Branding: Easily adapt to your app's look and feel */}
        <div className="feature-card">
          <div className="feature-details">
            <h3>Customizable</h3>
            <p>Flexibility to customize and brand the login experience to match your application.</p>
          </div>
          <div className="feature-icon">
            <FontAwesomeIcon icon={faCode} />
          </div>
        </div>

        {/* Feature Card 18: Internationalization and Localization: Adapt to different regions and languages */}
        <div className="feature-card">
          <div className="feature-details">
            <h3>Internationalization</h3>
            <p>Adapt to different regions and languages with internationalization and localization support.</p>
          </div>
          <div className="feature-icon">
            <FontAwesomeIcon icon={faLanguage} />
          </div>
        </div>

      </div>

      {/* Buttons Section */}
      <div className="SSO-buttons">
        <a href="http://readthedocs.eupraxia.io/docs/xtremecloud-sso/xtremecloud-single-sign-on/" className="SSO-learn-more-button">Learn More</a>
        <a href="/contact" className="SSO-learn-more-button">Request a demo</a>
        <a href="/contact" className="SSO-learn-more-button">Contact us for a Quote</a>
      </div>

    </div>
  );
}

export default XtremeCloudSSO;
