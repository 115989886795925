import React, { useState } from 'react';
import './Products.css';

function Products() {
  const privateProductData = [
    {
      name: 'Federal Frontier Kubernetes Platform',
      image: '/images/Federal_Frontier-logo-transparent.png',
      description: 'Elevate your application deployments with Federal Frontier Kubernetes Platform (FKP). A multi-cluster Kubernetes solution that simplifies management, offers a wide array of platform services, and provides user-friendly interfaces for rapid deployment, enhanced stability, and efficiency. FKP ensures centralized control, streamlining cluster deployment, management and monitoring with various Kubernetes distributions, while enabling easy access to essential services and partner add-ons.',
      link: '/federal-frontier'
    },
    {
      name: 'Federal Frontier FleetEdge',
      image: '/images/ff_edge_big_2_transparent.png',
      description: 'Elevate your Kubernetes deployment with Federal Frontier Kubernetes Platform (FKP) by Eupraxia Labs—an innovative, CNCF-approved multi-cluster Kubernetes solution that simplifies management, offers a wide array of platform services, and provides a user-friendly interface for rapid deployment, enhanced stability, and efficiency. FKP Enterprise ensures centralized control, streamlining cluster management and compatibility with various Kubernetes distributions, while enabling easy access to essential services and partner add-ons.',
      link: '/frontier-edge'
    },
    {
      name: 'XtremeCloud Single Sign-On',
      image: '/images/XCSSO-logo-1-transparent.png',
      description: 'XtremeCloud Single Sign-On (SSO) is a robust identity and access management (IAM) platform, offering comprehensive single sign-on (SSO) capabilities across multiple cloud environments and data centers. It empowers businesses with multi-cloud deployment options, efficient load balancing, and seamless integration with major Cloud Service Providers (CSPs) for resiliency. With a focus on flexibility, XtremeCloud SSO supports OpenID Connect (OIDC) and SAML 2.0 protocols, allowing clients and services to be secured according to specific needs. It offers adaptable adapters for various platforms, enhancing security for Kubernetes applications across both single-tenant and multi-tenant deployments.',
      link: '/xtreme-cloud-sso'
    }
  ];
  const publicProductData = [
    {
      name: 'AirEdge',
      image: '/images/AirEdge4.png', // Replace with actual image path
      description: "AirEdge is a specialized version of the FleetEdge solution, tailored for aerial applications in government sectors. It's designed to seamlessly integrate with various types of aircraft, from drones to manned aircraft, providing robust Kubernetes deployment capabilities in the sky. This solution excels in managing airborne communication and data systems, ensuring secure, reliable, and real-time data transmission even in challenging atmospheric conditions. AirEdge supports a wide range of aviation-specific applications, including surveillance, reconnaissance, and emergency response operations. Its lightweight architecture ensures minimal impact on aircraft performance while maintaining high levels of security and compliance with aviation standards.", // Replace with actual description
      link: '/fleetedge-air' // Replace with actual link
    },
    {
      name: 'LandEdge',
      image: '/images/GovEdge - LandEdge3.png', // Replace with actual image path
      description: "LandEdge is engineered to meet the demanding needs of terrestrial government operations. This solution is ideal for deployment in a variety of land-based environments, from urban infrastructures to remote and rugged terrains. It enables efficient management and operation of ground-based Kubernetes clusters, offering unparalleled support for mobile and stationary data centers. LandEdge is particularly suitable for military bases, emergency response units, and infrastructure monitoring systems, providing a secure, scalable, and resilient platform for critical data handling and communication tasks. Its robust design ensures reliable performance in various climatic and environmental conditions, making it an essential tool for government agencies requiring land-based operational capabilities.", // Replace with actual description
      link: '/fleetedge-land' // Replace with actual link
    },
    {
      name: 'SeaEdge',
      image: '/images/GovEdge - SeaEdge4.png', // Replace with actual image path
      description: "SeaEdge is a maritime-focused variant of the FleetEdge solution, designed specifically for naval and oceanic applications within government operations. This platform is adept at handling the unique challenges of maritime environments, offering stable and secure Kubernetes cluster deployment on ships, submarines, and maritime stations. It supports a wide array of naval operations, including oceanic research, maritime security, and naval logistics. SeaEdge is built to withstand harsh marine conditions, ensuring continuous operation even in high seas and varying weather conditions. Its advanced features include support for underwater communication systems, integration with nautical navigation technologies, and real-time data processing capabilities for marine-based tasks.", // Replace with actual description
      link: '/fleetedge-sea' // Replace with actual link
    }
  ];


  const [selectedProduct, setSelectedProduct] = useState(privateProductData[0]);

  const handleProductChange = (product) => {
    setSelectedProduct(product);
  };

  return (
    <div className="products-page">
      <section className="overview-section">
        <div className="overview-content">
          <h1>Unlock the Power of Cloud Solutions</h1>
          <p>Experience innovation and efficiency with our application platform.</p>
        </div>
      </section>
      <div className="quick-look-steps">
         <div className="quick-look-step">
           <img src="/images/innovation-art.png" alt="Rapid Deployment" />
           <p>Rapid Deployment</p>
         </div>
         <div className="quick-look-step">
           <img src="/images/shield-art.png" alt="Reliability" />
           <p>Ensuring Stability</p>
         </div>
         <div className="quick-look-step">
           <img src="/images/lock-art.png" alt="Security" />
           <p>Establishing Security</p>
         </div>
         <div className="quick-look-step">
           <img src="/images/revenue-art.png" alt="Return on Investment" />
           <p>Rapid Return on Investment</p>
         </div>
      </div>


      <div className="sectors-containers">
        <div className="sectors-container">
            <div className="sector-heading">
              <h2>GovEdge</h2>
            </div>
            <div className="product-tabs new-product-row">
              {publicProductData.map((product) => (
                <div
                  key={product.name}
                  className={`product-tab ${product.name === selectedProduct.name ? 'active' : ''}`}
                  onClick={() => handleProductChange(product)}
                >
                  {product.name}
                </div>
              ))}
            </div>
        </div>
        <div className="sectors-container">
            <div className="sector-heading">
              <h2>FleetEdge</h2>
            </div>
            <div className="product-tabs">
              {privateProductData.map((product) => (
                <div
                  key={product.name}
                  className={`product-tab ${product.name === selectedProduct.name ? 'active' : ''}`}
                  onClick={() => handleProductChange(product)}
                >
                  {product.name}
                </div>
              ))}
            </div>
        </div>
      </div>



      <section className="quick-look-section">
        <div className="quick-look-content">
          <div className="quick-look-image">
            <img src={selectedProduct.image} alt={selectedProduct.name} />
          </div>
          <div className="quick-look-text">
            <a
              href={selectedProduct.link}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <h2>{selectedProduct.name}</h2>
            </a>
            <p>{selectedProduct.description}</p>
            <a href={selectedProduct.link} className="learn-more-button">Learn More</a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Products;
