import React from 'react';
import './Team.css';

function Team() {
  return (
    <section className="team-section">
      <h2 className="team-heading">Meet The Team</h2>
      <p className="team-text">Meet the minds behind our innovative solutions.</p>
      <div className="team-members">
        <div className="team-member">
          <img src="/images/david-brewer.jpg" alt="Team Member 1" className="team-member-image" />
          <h3 className="team-member-name">David Brewer</h3>
          <p className="team-member-role">Senior Vice President Cloud Architecture</p>
        </div>
        <div className="team-member">
          <img src="/images/phil-richards.jpg" alt="Team Member 2" className="team-member-image" />
          <h3 className="team-member-name">Phil Richards</h3>
          <p className="team-member-role">Senior Vice President Data Architecture</p>
        </div>
        <div className="team-member">
          <img src="/images/Alex.jpg" alt="" className="team-member-image" />
          <h3 className="team-member-name">Alex Brewer</h3>
          <p className="team-member-role">Director of Sales</p>
        </div>
        <div className="team-member">
          <img src="/images/jeremy-estrada.jpg" alt="Team Member 2" className="team-member-image" />
          <h3 className="team-member-name">Jeremy Estrada</h3>
          <p className="team-member-role">Head of Development</p>
        </div>
        <div className="team-member">
          <img src="/images/francis-williams.jpg" alt="" className="team-member-image" />
          <h3 className="team-member-name">Francis Williams</h3>
          <p className="team-member-role">Software Engineer</p>
        </div>
      </div>
    </section>
  );
}

export default Team;
