import React from 'react';
import './ProductHighlight.css'; // Make sure the CSS file path is correct
import { Link } from 'react-router-dom';

function ProductHighlight() {
  return (
    <div className="product-highlight">
      <div className="product-highlight-content">
        <div className="product-highlight-title">
            <h1>Experience the Future with Federal Frontier</h1>
        </div>
        <div className="product-highlight-image">
          {/* Replace with your own product image */}
          <img src="/images/Federal_Frontier-logo-transparent.png" alt="Federal Frontier Application Platform" />
        </div>
        <div className="product-highlight-text">
          <p>
            Say goodbye to silos and hello to a unified cloud journey. The Federal Frontier Application
            Platform propels your organization with a DevSecOps-based solution that's built for secure,
            agile, and efficient transformation.
          </p>
          <p>
            Discover the power of real-time situational awareness with Mirantis Lens. Whether it's
            development, debugging, or monitoring, you're in control of your apps across clusters.
          </p>
          <p>
            Collaborate seamlessly with our Eupraxia Labs Professional Services Team to elevate your
            application development, migration, and legacy operations. The Cluster-API (CAPI)-based
            Management Cluster empowers both legacy and cloud-native workloads across all environments.
          </p>
        </div>
        <div className="product-highlight-cta">
            <Link to="/federal-frontier">
                <button className="explore-button">Explore Federal Frontier</button>
            </Link>
        </div>
      </div>
    </div>
  );
}

export default ProductHighlight;
