import React, { useState } from 'react';
import './FederalFrontier.css'; // Make sure the CSS file path is correct

function FederalFrontier() {
  // Initialize state to keep track of section visibility
  const [sectionVisibility, setSectionVisibility] = useState(Array(12).fill(true));

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to toggle section visibility
  const toggleSection = (index) => {
    const newVisibility = [...sectionVisibility];
    newVisibility[index] = !newVisibility[index];
    setSectionVisibility(newVisibility);
  };

  return (
    <div className="federal-frontier">
      <div className="federal-frontier-content">
        <div className="federal-frontier-text">
          <h1>Federal Frontier Kubernetes Platform (FKP)</h1>
          <h2>
            Feature-rich, cost-effective, and easy-to-deploy multi-cluster Kubernetes solution that leverages open-source innovations while avoiding vendor lock-in.
          </h2>
          <p>
            FKP enables rapid deployment, ensuring stability, reliability, security, and a rapid return on investment. It offers a wide range of platform services, including networking, storage, logging, and monitoring.
          </p>
          <p>
            Partner add-ons include the complete Mirantis (formerly Docker) product range, Netris for cloud-like VPC networking anywhere on-premises or bare metal clouds (BMC), and Lightbits Labs for block-based software-only and NVMe/TCP architecture, combined with intelligent flash management and enterprise-grade data services.
          </p>
        </div>
        <div className="federal-frontier-image">
          <img src="/images/Federal_Frontier-logo-transparent.png" alt="FKP Kubernetes Platform" />
        </div>
      </div>

      {/* First Row */}
      <div className="row">
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#feature-summary" className="section open">
          <div className="details">
            <div className="summary">DevSecOps Simplification</div>
            {sectionVisibility[0] && (
              <p>
                FKP simplifies DevSecOps by offering an automated, integrated, and inherently secure Kubernetes stack. It streamlines operations, making it easier for developers.
              </p>
            )}
          </div>
        </a>

        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#feature-summary" className="section open">
          <div className="details">
            <div className="summary">Uncompromising Security</div>
            {sectionVisibility[1] && (
              <p>
                FKP surpasses security guidelines, undergoes vulnerability scanning, and simplifies air-gapped deployments. It ensures the highest level of security.
              </p>
            )}
          </div>
        </a>

        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#feature-summary" className="section open">
          <div className="details">
            <div className="summary">Intuitive Troubleshooting</div>
            {sectionVisibility[2] && (
              <p>
                FKP guarantees uniformity in cluster creation, regardless of the deployment location. This streamlines production deployments for cloud and on-premises environments.
              </p>
            )}
          </div>
        </a>

        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-cli-user-guide/" className="section open">
          <div className="details">
            <div className="summary">Frontier CLI</div>
            {sectionVisibility[3] && (
              <p>
                Frontier CLI is a front-end application for FKP, simplifying workload cluster deployment, management, and modification.
              </p>
            )}
          </div>
        </a>
      </div>

      {/* Second Row */}
      <div className="row">
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#eliminate-kubernetes-skills-gap" className="section open">
          <div className="details">
            <div className="summary">Pure Upstream Kubernetes</div>
            {sectionVisibility[4] && (
              <p>
                FKP Gov is constructed on pure upstream open-source Kubernetes, avoiding complications associated with forked versions and dependencies.
              </p>
            )}
          </div>
        </a>

        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#feature-summary" className="section open">
          <div className="details">
            <div className="summary">Cluster API for Enhanced Productivity</div>
            {sectionVisibility[5] && (
              <p>
                Cluster API by FKP guarantees uniformity in cluster creation across environments, enabling expedited production deployments.
              </p>
            )}
          </div>
        </a>

        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#fkp-enterprise-solution" className="section open">
          <div className="details">
            <div className="summary">Enterprise-Ready Solution</div>
            {sectionVisibility[6] && (
              <p>
                FKP is an enterprise-ready solution designed for large organizations, offering scalability and support for complex DevSecOps workloads.
              </p>
            )}
          </div>
        </a>

        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#feature-summary" className="section open">
          <div className="details">
            <div className="summary">Effortless Air-Gapping</div>
            {sectionVisibility[7] && (
              <p>
                FKP Gov offers comprehensive support for air-gapped clusters, providing an easy and swift deployment advantage.
              </p>
            )}
          </div>
        </a>
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#flexible-scalable-and-continuous-deployments" className="section open">
          <div className="details">
            <div className="summary">TrailBoss</div>
            {sectionVisibility[11] && (
              <p>TrailBoss is an integrated management tool for overseeing multiple Kubernetes clusters across various environments, ensuring standardized, secure, and efficient operations under a unified governance model.
              </p>
            )}
          </div>
        </a>
      </div>

      {/* Third Row */}
      <div className="row">
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-outpost-user-guide/" className="section open">
          <div className="details">
            <div className="summary">Outpost UI</div>
            {sectionVisibility[8] && (
              <p>
                Outpost UI simplifies managing and monitoring Kubernetes clusters, enabling more efficient DevSecOps operations.
              </p>
            )}
          </div>
        </a>

        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#feature-summary" className="section open">
          <div className="details">
            <div className="summary">End-to-End Security</div>
            {sectionVisibility[9] && (
              <p>
                FKP's security model covers the entire stack, ensuring protection and compliance with regulatory standards.
              </p>
            )}
          </div>
        </a>

        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#feature-summary" className="section open">
          <div className="details">
            <div className="summary">Continuous Innovation</div>
            {sectionVisibility[10] && (
              <p>
                FKP is committed to continuous innovation, providing regular updates and enhancements to stay at the forefront of Kubernetes technology.
              </p>
            )}
          </div>
        </a>

        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/#flexible-scalable-and-continuous-deployments" className="section open">
          <div className="details">
            <div className="summary">Flexible Deployment</div>
            {sectionVisibility[11] && (
              <p>
                FKP offers flexible deployment options, optimizing resource usage and aligning IT operations with business objectives.
              </p>
            )}
          </div>
        </a>
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-addons-guide/pioneerai/"className="section open">
          <div className="details">
            <div className="summary">Pioneer AI</div>
            {sectionVisibility[11] && (
              <p>
                PioneerAI, powered by OpenAI and part of FKP, offers comprehensive diagnostics and solutions for issues in any Frontier Kubernetes cluster, covering all system types.
              </p>
            )}
          </div>
        </a>
      </div>
      {/* "How It Works" Section
      <div className="how-it-works">
        <div className="how-it-works-text">
          <h2>How It Works</h2>

          <div className="how-it-works-image">
            <img
                src="/images/ff-diagram.png"
                alt="FKP Kubernetes Platform"
              onClick={openModal}
            />
          </div>


          <p style={{ textAlign: 'left' }}>
            <ol>
              <li>
                <strong>Overall Goal:</strong> The Federal Frontier Platform deploys Kubernetes clusters across various infrastructure providers like Microsoft Azure, AWS, Data Centers, Bare Metal Clouds (BMC), and more.
              </li>
            </ol>
            <ol start="2">
              <li>
                <strong>Process Flow:</strong>
                <ol type="I">
                  <li>
                    A user or developer initiates a request for a new Kubernetes workload cluster through the Frontier CLI or Outpost (a web UI).
                  </li>
                  <li>
                    The request reaches the “Frontier API Services” (FAS). This service processes the API (in JSON format) request and then asks the “Frontier Management Cluster” (FMC) to allocate necessary resources via the Cluster API (CAPI).
                  </li>
                  <li>
                    Depending on where the Kubernetes cluster is to be deployed (Azure, AWS, Bare Metal), appropriate virtual machines or servers get provisioned. These systems run Ubuntu OS and come with a specified Kubernetes distribution. Storage solutions like Lightbits Labs' Block Storage is a powerful add-on with an outstanding ROI and blazing IOPS.
                  </li>
                  <li>
                    A bootstrap and control plane provider takes over to finalize the setup of the Kubernetes nodes. The new cluster nodes, whether in data centers or edge locations, are equipped with the chosen OS, Kubernetes distribution, and other necessary Federal Frontier add-ons.
                  </li>
                </ol>
              </li>
            </ol>
            <ol start="3">
              <li>
                <strong>Infrastructure Breakdown:</strong>
                <ul>
                  <li>
                    <strong>Cloud Providers:</strong>
                    <ul>
                      <li>
                        Microsoft Azure: Deploys Kubernetes clusters with nodes labeled “master” (control plane) and “node” (worker). It uses Lightbits Block Storage.
                      </li>
                      <li>
                        AWS (Amazon Web Services): Similar setup as Azure.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Bare Metal & Data Center:</strong> Deployments here can be on raw hardware or virtualized platforms like K8s, and they may use Lightbits Block Storage. Canonical MAAS (Metal as a Service) is also involved, which is a service for making it easier to set up physical servers as if they were virtual machines.
                  </li>
                  <li>
                    <strong>Edge Nodes:</strong> Rugged or specially designed hardware nodes (like ARM64/AMD64 based devices) can be set up, potentially with lightweight Kubernetes distributions like K3s, MicroK8s, K0s, and provisioned to be shipped on-site.
                  </li>
                </ul>
              </li>
            </ol>
            <ol start="4">
              <li>
                <strong>Core Components:</strong>
                <ul>
                  <li>
                    <strong>Cluster API (CAPI):</strong> It’s the central API that manages cluster deployment across different providers. Three specific providers are:
                    <ul>
                      <li>
                        CAPA Provider (AWS)
                      </li>
                      <li>
                        CAPZ Provider (Azure)
                      </li>
                      <li>
                        CAPMAAS Provider (for Canonical MAAS)
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Frontier CLI:</strong> A command-line interface for users/developers to interact with the platform.
                  </li>
                  <li>
                    <strong>Frontier API Services (FAS):</strong> Processes user requests and communicates with the Cluster API.
                  </li>
                  <li>
                    <strong>Frontier Management Cluster (FMC):</strong> Manages the requests for infrastructure provisioning.
                  </li>
                </ul>
              </li>
            </ol>
            <ol start="5">
              <li>
                <strong>Security:</strong> The system has security measures in place, with JWT (JSON Web Token) protection and OIDC (OpenID Connect) authentication mechanisms, especially for the web UI “Outpost”. Netris and Tigera Calico provide network virtualization, Kubernetes CNI, and network policies to provide multi-tenant isolation and micro-segmentation when desired. The Buoyant Linkerd Service Mesh provides end-to-end FIPS 140-2 encryption.
              </li>
            </ol>
            <ol start="6">
              <li>
                <strong>Collaborators/Partners:</strong> Various companies and products make the Federal Frontier Platform an innovative market leader by providing core components. This includes Canonical, Mirantis, Tigera, Netris, and Lightbits Labs.
              </li>
            </ol>
            <br />
            This platform allows for the streamlined deployment and management of Kubernetes clusters across multiple types of infrastructure, from cloud providers to edge nodes. It incorporates security measures, storage solutions, and is built on a modular architecture allowing for expansion and integration of other services/providers in the future.
          </p>
        </div>
      </div>
      */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the same image at a larger scale*/}
            <img
              src="/images/ff-diagram.png"
              alt="FKP Kubernetes Platform"
              className="modal-image"
            />
          </div>
        </div>
      )}
      {/* Learn More Section */}
      <div className="learn-more">
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="learn-more-button" target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
        <a href="/contact" className="learn-more-button">
          Request a demo
        </a>
      </div>
    </div>
  );
}

export default FederalFrontier;
