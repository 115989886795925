// Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="wrapper">
      <footer className="footer">
          <div className="logo">
            <a href="/" /* Add the link to your homepage here */>
              <img src="/images/eupraxia-logo-transparent.png" alt="Your Tech Company Logo" />
            </a>
          </div>
          <div className="footer-links">
            <ul>
              <li className="mainNavLink">
                <a className="mainNavLinkText">Resources</a>
                <ul className="submenu">
                  <li><a href="http://readthedocs.eupraxia.io/" target="_blank" rel="noopener noreferrer"><span className="subLinkText">Documentation</span></a></li>
                  <li><a href="whycapi"><span className="subLinkText">Why CAPI?</span></a></li>
                  <li><a href="/security"><span className="subLinkText">Security</span></a></li>
                </ul>
              </li>
              <li className="mainNavLink">
                <a href="/products" className="mainNavLinkText">Products</a>
                <ul className="submenu">
                  <li><a href="/federal-frontier"><span className="subLinkText">Federal Frontier</span></a></li>
                  <li><a href="/frontier-edge"><span className="subLinkText">Frontier FleetEdge</span></a></li>
                  <li><a href="/xtreme-cloud-sso"><span className="subLinkText">XtremeCloud SSO</span></a></li>
                </ul>
              </li>
              <li className="mainNavLink"><a href="/blog" className="mainNavLinkText">Blog</a></li>
              <li className="mainNavLink"><a href="/team" className="mainNavLinkText">Team</a></li>
              <li className="mainNavLink"><a href="/contact" className="mainNavLinkText">Contact</a></li>
            </ul>
          </div>
      </footer>
      <p className="footer-copyright">&copy; {new Date().getFullYear()} Eupraxia Labs All rights reserved.</p>
    </div>
  );
}

export default Footer;
