import React, { useState } from 'react';
import './FleetEdgeAir.css'; // Import the specific CSS file for FleetEdgeAir

function FleetEdgeAir() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="fleet-edge-air">
      {/* Introduction/Overview Section */}
      <section className="fleet-edge-air-overview">
        <div className="fleet-edge-air-header">
          <h1>AirEdge</h1>
          <p>AirEdge revolutionizes aerial operations with its advanced Kubernetes management for government aviation. Designed for a wide range of aircraft, it provides secure, real-time data processing and communication capabilities essential for surveillance, reconnaissance, and emergency response in the skies.</p>
        </div>
        <div className="fleet-edge-air-image-logo">
          <img src="/images/fleetedgeAir/png/AirEdge4.png" alt="FleetEdge Air Product" />
        </div>
      </section>

      {/* First Div Below (Detailed Explanation and Image) */}
      <div className="fleet-edge-air-detailed-section">
        {/* Detailed Explanation */}
        <div className="fleet-edge-air-explanation">
          <h2>AirEdge</h2>
          <p>AirEdge offers a seamless and robust platform for deploying Kubernetes clusters in aerial environments. It's engineered for high performance in both manned and unmanned aircraft, ensuring reliable operation during critical missions. This solution is key for dynamic data handling, network communication, and mission-critical applications in the air.</p>
        </div>

        {/* Image */}
        <div className="fleet-edge-air-image">
          <img
            src="/images/DHI-Diagram3.png"
            alt="FleetEdge Air Diagram"
            onClick={openModal}
          />
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the same image at a larger scale */}
            <img
              src="/images/DHI-Diagram3.png"
              alt="FleetEdge Air Diagram"
              className="modal-image"
            />
          </div>
        </div>
      )}

      {/* Second Div Below (Benefits Section) */}
      <div className="fleet-edge-air-benefits-section">
        <h2>Benefits</h2>
        <div className="fleet-edge-air-benefits-list">
            <div className="benefit-icon">
              <img src="/images/shield-art.png" alt="Benefit 1" />
              <h3>Enhanced Security and Compliance</h3>
              <p>AirEdge automates security measures and ensures compliance with government standards, making your deployments more secure and reducing the risk of vulnerabilities.</p>
            </div>
            <div className="benefit-icon">
              <img src="/images/lock-art.png" alt="Benefit 2" />
              <h3>Simplified Management and Maintenance</h3>
              <p>With AirEdge, managing Kubernetes clusters becomes straightforward, and it offers long-term support, reducing the complexity of ongoing maintenance and keeping your clusters secure and up-to-date.</p>
            </div>
            <div className="benefit-icon">
              <img src="/images/revenue-art.png" alt="Benefit 3" />
              <h3>Efficiency and Scalability</h3>
              <p>AirEdge simplifies the setup of Kubernetes clusters, saving time and effort. It can quickly adapt to different environments, making it easy to scale as your needs grow.</p>
            </div>
        </div>
      </div>
      <div className="FE-features-div">
        <h2 className="FE-features-header">Features</h2>
        <div className="FE-row">
          {/* AirEdge for Aviation */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="FE-summary">AirEdge for Aviation</div>
              <p>
                Tailored for aviation environments, AirEdge effectively handles temperature shifts, high-altitude pressures, and in-flight tremors, ensuring reliable airborne functionality.
              </p>
            </div>
          </a>

          {/* Advanced Kubernetes Management */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">Advanced Kubernetes Management</div>
              <p>
                Utilizing the Federal Frontier Kubernetes Platform, AirEdge simplifies the management of Kubernetes clusters, enhancing operational efficiency.
              </p>
            </div>
          </a>

          {/* Zero Trust Architecture */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">Zero Trust Architecture</div>
              <p>
                Incorporating advanced security protocols including Calico CNI and Linkerd service mesh, AirEdge ensures robust security and data protection.
              </p>
            </div>
          </a>

          {/* Embedded Storage & ARM64 Processing */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">Embedded Storage & ARM64 Processing</div>
              <p>
                Powered by NVMe/TCP storage solutions and ARM64 architecture, AirEdge offers optimal performance and SWaP dynamics.
              </p>
            </div>
          </a>
        </div>
      </div>

      <div className="FE-technical-specifications">
        <h2 className="FE-technical-specifications-header">Technical Specifications</h2>
        <p className="FE-technical-specifications-paragraph">
          AirEdge, designed for aviation environments, utilizes the NXP Layerscape LX2160a ARM64 SoC, ensuring optimal performance for manned and unmanned aircraft. The ARM64 architecture, known for its balance of performance and power, contributes to Size, Weight, and Power (SWaP) dynamics. AirEdge features NVMe/TCP storage solutions from Lightbits Labs, enhancing data security. Technical specifications include temperature-resistant capabilities, addressing temperature shifts, high-altitude pressures, and in-flight tremors. The architecture supports advanced security protocols, including Calico CNI and Linkerd service mesh, ensuring robust security during airborne operations.
          Additionally, AirEdge integrates with the Federal Frontier Kubernetes Platform, streamlining Kubernetes cluster management and enhancing operational efficiency. The implementation of a Zero Trust Architecture, with advanced security protocols like Calico CNI and Linkerd service mesh, ensures robust security and data protection for AirEdge deployments. The embedded storage and ARM64 processing, powered by NVMe/TCP storage solutions and ARM64 architecture, provide optimal performance and Size, Weight, and Power (SWaP) dynamics, making AirEdge a reliable solution for aviation environments.
        </p>
      </div>



      <div className="learn-more">
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-learn-more-button" target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
        <a href="https://eupraxialabs.com/images/datasheet.pdf" target="_blank" className="FE-learn-more-button">
          View Datasheet
        </a>
        <a href="/contact" className="FE-learn-more-button">
          Request a Demo
        </a>
        <a href="/contact" className="FE-learn-more-button">
          Contact us for a Quote
        </a>
      </div>
    </div>
  );
}

export default FleetEdgeAir;
