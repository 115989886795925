import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop'; // Import the custom ScrollToTop component
import Documentation from './components/Documentation';
import Products from './components/Products';
import Solutions from './components/Solutions';
import Security from './components/Security';
import WhyCAPI from './components/WhyCAPI';
import Team from './components/Team';
import Contact from './components/Contact';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Blog from './components/Blog';
import FederalFrontier from './components/FederalFrontier';
import XtremeCloudSSO from './components/XtremeCloudSSO';
import FrontierEdge from './components/FrontierEdge';
import Article from './components/Article';
import FleetEdgeSea from './components/FleetEdgeSea';
import FleetEdgeLand from './components/FleetEdgeLand';
import FleetEdgeAir from './components/FleetEdgeAir';

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Add the ScrollToTop component here */}
      <div className="App">
        {/* Navigation */}
        <Navbar />

        {/* Content */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/#documentation" element={<Documentation />} />
          <Route path="/products" element={<Products />} />
          <Route path="/security" element={<Security />} />
          <Route path="/whycapi" element={<WhyCAPI />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/federal-frontier" element={<FederalFrontier />} />
          <Route path="/xtreme-cloud-sso" element={<XtremeCloudSSO />} />
          <Route path="/frontier-edge" element={<FrontierEdge />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:articleId/:modifiedTitle" element={<Article />} />
          <Route path="/fleetedge-air" element={<FleetEdgeAir />} />
          <Route path="/fleetedge-land" element={<FleetEdgeLand />} />
          <Route path="/fleetedge-sea" element={<FleetEdgeSea />} />
        </Routes>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
