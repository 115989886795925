import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobileScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check
    checkScreenWidth();

    // Event listener for window resize
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
      <nav>
        <div className="logo">
          <a href="/" /* Add the link to your homepage here */>
            <img src="/images/eupraxia-logo-transparent.png" alt="Your Tech Company Logo" />
          </a>
        </div>
        {isMobileScreen && (
          <div className={`menu-icon ${isMobileMenuOpen ? 'active' : ''}`} onClick={handleMobileMenuToggle}>
            &#9776; {/* Unicode hamburger icon */}
          </div>
        )}
        <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <li className="mainNavLink">
            <NavLink className="mainNavLinkText">Resources</NavLink>
            <ul className="submenu">
              <li><a href="http://readthedocs.eupraxia.io/" target="_blank" rel="noopener noreferrer"><span className="subLinkText">Documentation</span></a></li>
              <li><NavLink to="whycapi"><span className="subLinkText">Why CAPI?</span></NavLink></li>
              <li><NavLink to="/security"><span className="subLinkText">Security</span></NavLink></li>
            </ul>
          </li>
          <li className="mainNavLink">
            <NavLink to="/products" className="mainNavLinkText">Products</NavLink>
            <ul className="submenu">
              <li><NavLink to="/fleetedge-air"><span className="subLinkText">GovEdge - AirEdge</span></NavLink></li>
              <li><NavLink to="/fleetedge-land"><span className="subLinkText">GovEdge - LandEdge</span></NavLink></li>
              <li><NavLink to="/fleetedge-sea"><span className="subLinkText">GovEdge - SeaEdge</span></NavLink></li>
              <li><NavLink to="/federal-frontier"><span className="subLinkText">Federal Frontier Kubernetes Platform</span></NavLink></li>
              <li><NavLink to="/frontier-edge"><span className="subLinkText">Frontier FleetEdge</span></NavLink></li>
              <li><NavLink to="/xtreme-cloud-sso"><span className="subLinkText">XtremeCloud SSO</span></NavLink></li>
            </ul>
          </li>
          <li className="mainNavLink"><NavLink to="/blog" className="mainNavLinkText">Blog</NavLink></li>
          <li className="mainNavLink"><NavLink to="/team" className="mainNavLinkText">Team</NavLink></li>
          <li className="mainNavLink"><NavLink to="/contact" className="mainNavLinkText">Contact</NavLink></li>
        </ul>
      </nav>
    );
}

export default Navbar;
