// FleetEdgeSea.js
import React, { useState } from 'react';
import './FleetEdgeSea.css'; // Import the specific CSS file for FleetEdgeSea

function FleetEdgeSea() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="fleet-edge-sea">
      {/* Introduction/Overview Section */}
      <section className="fleet-edge-sea-overview">
        <div className="fleet-edge-sea-header">
          <h1>SeaEdge</h1>
          <p>SeaEdge offers advanced Kubernetes management solutions for government maritime operations. Designed for various vessels, it ensures secure data processing and communication capabilities, supporting maritime surveillance, monitoring, and response activities.</p>
        </div>
        <div className="fleet-edge-sea-image-logo">
          <img src="/images/fleetedgeSea/png/GovEdge - SeaEdge4.png" alt="FleetEdge Sea Product" />
        </div>
      </section>

      {/* First Div Below (Detailed Explanation and Image) */}
      <div className="fleet-edge-sea-detailed-section">
        {/* Detailed Explanation */}
        <div className="fleet-edge-sea-explanation">
          <h2>SeaEdge</h2>
          <p>SeaEdge provides a robust platform for deploying Kubernetes clusters in maritime environments. It is engineered to deliver high performance and reliability for government maritime operations, ensuring seamless data handling and communication for critical tasks at sea.</p>
        </div>

        {/* Image */}
        <div className="fleet-edge-sea-image">
          <img
            src="/images/DHI-Diagram3.png"
            alt="FleetEdge Sea Diagram"
            onClick={openModal}
          />
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the same image at a larger scale */}
            <img
              src="/images/DHI-Diagram3.png"
              alt="FleetEdge Sea Diagram"
              className="modal-image"
            />
          </div>
        </div>
      )}

      {/* Second Div Below (Benefits Section) */}
      <div className="fleet-edge-sea-benefits-section">
        <h2>Benefits</h2>
        <div className="fleet-edge-sea-benefits-list">
          <div className="benefit-icon">
            <img src="/images/shield-art.png" alt="Benefit 1" />
            <h3>Enhanced Security and Compliance</h3>
            <p>SeaEdge automates security measures and ensures compliance with government standards, enhancing the security of your maritime operations.</p>
          </div>
          <div className="benefit-icon">
            <img src="/images/lock-art.png" alt="Benefit 2" />
            <h3>Simplified Management and Maintenance</h3>
            <p>Managing Kubernetes clusters becomes straightforward with SeaEdge, reducing complexity and ongoing maintenance tasks.</p>
          </div>
          <div className="benefit-icon">
            <img src="/images/revenue-art.png" alt="Benefit 3" />
            <h3>Efficiency and Scalability</h3>
            <p>SeaEdge simplifies cluster setup, saving time and effort, and can easily scale to meet the growing needs of your maritime operations.</p>
          </div>
        </div>
      </div>
      <div className="FE-features-div">
        <h2 className="FE-features-header">Features</h2>
        <div className="FE-row">
          {/* SeaEdge for Maritime Operations */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">SeaEdge for Maritime Operations</div>
              <p>
                Designed for maritime robustness, SeaEdge effectively counters shock and vibration, anchoring reliable naval operations in challenging maritime environments.
              </p>
            </div>
          </a>

          {/* Advanced Kubernetes Management */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">Advanced Kubernetes Management</div>
              <p>
                Orchestrated by the Federal Frontier Kubernetes Platform, SeaEdge simplifies the management of Kubernetes clusters, ensuring efficient and hands-off node operations in maritime scenarios.
              </p>
            </div>
          </a>

          {/* Zero Trust Architecture */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">Zero Trust Architecture</div>
              <p>
                Integrating advanced security protocols including Calico CNI and Linkerd service mesh, SeaEdge ensures robust security and data protection in maritime applications.
              </p>
            </div>
          </a>

          {/* Embedded Storage & ARM64 Processing */}
          <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-section open">
            <div className="FE-details">
              <div className="summary">Embedded Storage & ARM64 Processing</div>
              <p>
                Powered by NVMe/TCP storage solutions and ARM64 architecture, SeaEdge offers optimal performance and Size, Weight, and Power (SWaP) dynamics for maritime computing infrastructure.
              </p>
            </div>
          </a>
        </div>
      </div>

      <div className="FE-technical-specifications">
        <h2 className="FE-technical-specifications-header">Technical Specifications</h2>
        <p className="FE-technical-specifications-paragraph">
          SeaEdge, designed for maritime environments, is powered by the NXP Layerscape LX2160a ARM64 SoC, providing optimal performance for naval operations. The ARM64 architecture, renowned for its performance and power balance, contributes to the Size, Weight, and Power (SWaP) dynamics of SeaEdge, making it resilient to shock, and vibration. SeaEdge features NVMe/TCP storage solutions from Lightbits Labs, ensuring secure data storage and management. Technical specifications include resistance to shock, and vibration, making SeaEdge suitable for reliable naval operations in challenging maritime conditions. The architecture supports advanced security protocols, including Calico CNI and Linkerd service mesh, ensuring robust security during naval missions.
          Additionally, SeaEdge seamlessly integrates with the Federal Frontier Kubernetes Platform, streamlining Kubernetes cluster management for maritime applications. The adoption of a Zero Trust Architecture, along with advanced security protocols such as Calico CNI and Linkerd service mesh, ensures robust security and data protection for SeaEdge deployments. The embedded storage and ARM64 processing, powered by NVMe/TCP storage solutions and ARM64 architecture, provide optimal performance and SWaP dynamics, making SeaEdge a reliable solution for maritime environments.
        </p>
      </div>

      <div className="learn-more">
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-learn-more-button" target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
        <a href="https://eupraxialabs.com/images/datasheet.pdf" target="_blank" className="FE-learn-more-button">
          View Datasheet
        </a>
        <a href="/contact" className="FE-learn-more-button">
          Request a Demo
        </a>
        <a href="/contact" className="FE-learn-more-button">
          Contact us for a Quote
        </a>
      </div>
    </div>
  );
}

export default FleetEdgeSea;
