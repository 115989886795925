import React from 'react';
import './Security.css'; // Import your CSS file
import './FederalFrontier.css'; // Import your CSS file

function Security() {
  return (
    <div className="cluster-api-security">
      {/* Section 1: Federal Frontier's Zero Trust Security */}
      <div className="overview-cluster-api-section">
        <div className="overview-section-cluster-api-header">
          <h1>Federal Frontier's Zero Trust Security</h1>
        </div>
        <div className="overview-section-cluster-api-content">
          <p>
            Trust Nothing. Secure Everything. Dive deep into our unparalleled Zero Trust Architecture (ZTA) -
            an unmatched commitment to IT security. At Federal Frontier, we default to distrust, validating every
            identity and every request, whether inside or outside of your network's perimeter.
          </p>
        </div>
      </div>

      {/* Section 3: ZTA Applications */}
      <div className="cluster-api-implementation-section">
        <div className="cluster-api-implementation-text">
          <h2>Easily Apply Zero Trust</h2>
          <ul className="cluster-api-implementation-list">
            <li>
              <strong>Netris:</strong> Automating network configurations, Netris bases access on identity, not mere IP-based ACL’s. As threats evolve, it dynamically adjusts to security protocols.
            </li>
            <li>
              <strong>Linkerd:</strong> Beyond being just a service mesh, Linkerd is your security guardian, utilizing mutual TLS (mTLS) encrypting inter-service traffic, verifying service identities, with fine-grained control of traffic flow.
            </li>
            <li>
              <strong>Calico Enterprise:</strong> Dictating the traffic routes within Kubernetes, Calico implements policy-driven micro-segmentation, instantly detecting potential threats and granting unparalleled visibility.
            </li>
          </ul>
        </div>
        <div className="cluster-api-image">
          {/* Replace the image source */}
          <img src="/images/cpudashboardscreen.png" alt="Zero Trust Architecture Applications" />
        </div>
      </div>

      {/* Section 4: Synergy in Action */}
      <div className="ZT-cluster-api-section-both">
        <div className="ZT-cluster-api-section">
          <div className="ZT-section-cluster-api-header">
            <h2>Synergy in Action</h2>
          </div>
          <div className="ZT-section-cluster-api-content">
            <p>
              A user/service request? Netris dynamically tailors the network, reflecting the intent &amp; policies.
              Linkerd takes over, encrypting the request via mTLS, ensuring a secure, targeted traffic route.
              Before reaching its Kubernetes destination, Calico verifies if network policies allow the request,
              adding an extra layer of check and balance.
            </p>
          </div>
        </div>

        {/* Section 5: Endgame */}
        <div className="TLS-cluster-api-section">
          <div className="TLS-section-cluster-api-header">
            <h2>Endgame</h2>
          </div>
          <div className="TLS-section-cluster-api-content">
            <p>
              A seamless, fortified environment. Every single request is verified, routed securely, and authenticated.
              Continuous monitoring, real-time policy enforcement, and the combined prowess of Netris, Linkerd, and Calico
              ensure you're always several steps ahead of threats.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="section openS">
          <div className="detailsS">
            <div className="summaryS">Benchmark-Driven Security with CIS</div>
              <p>
                By aligning with the CIS Kubernetes Benchmark, Federal Frontier guarantees that its Kubernetes deployments are resistant to known vulnerabilities, ensuring optimal configurations from the initial installation.
              </p>
          </div>
        </div>

        <div className="section openS">
          <div className="detailsS">
            <div className="summaryS">Real-time Monitoring with Calico</div>
              <p>
                Calico’s integration offers real-time intrusion and abnormality detection. This continuous monitoring capability, coupled with detailed logging, provides unparalleled insight into system activities, enabling rapid response to emerging threats.
              </p>
          </div>
        </div>

        <div className="section openS">
          <div className="detailsS">
            <div className="summaryS">Hardened Infrastructure</div>
              <p>
                Leveraging Security Technical Implementation Guides (STIGs), Federal Frontier ensures Ubuntu-based nodes and Immutable OSes—whether on bare metal or VMs—are fortified against common cyber threats.
              </p>
          </div>
        </div>

        <div className="section openS">
          <div className="detailsS">
            <div className="summaryS">Advanced Network Controls via Calico Enterprise</div>
              <p>
                Through Calico Enterprise, Federal Frontier enforces granular network policies, combined with advanced micro-segmentation. This assures regulated communication within workloads, strengthening defense against internal and lateral threats.
              </p>
          </div>
        </div>
        <div className="section openS">
          <div className="detailsS">
            <div className="summaryS">Adaptive Updates</div>
              <p>
                In the digital realm, stagnation is vulnerability. Federal Frontier emphasizes periodic updates across all components, ensuring the platform is always equipped with the latest security enhancements.
              </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="section openS">
          <div className="detailsS">
            <div className="summaryS"> Secure Service Mesh with Linkerd</div>
              <p>
                Federal Frontier incorporates Linkerd, a FIPS 140-2 compliant service mesh. This ensures secure, observable, and reliable communication between services, adding another dimension of cybersecurity.
              </p>
          </div>
        </div>

        <div className="section openS">
          <div className="detailsS">
            <div className="summaryS">Comprehensive Access Management</div>
              <p>
                By emphasizing Role-Based Access Controls (RBAC), the platform maintains stringent access regulations. Combined with identity and access management solutions, such as Keycloak (OIDC/SAML Provider), and allowing for delegation to LDAP providers including Active Directory, Federal Frontier ensures the right people have the right access, no more, no less.
              </p>
          </div>
        </div>

        <div className="section openS">
          <div className="detailsS">
            <div className="summaryS">Regular Audits and Vulnerability Assessments</div>
              <p>
                Proactive security is the best defense. Federal Frontier includes regular security assessments, aligning with the ever-evolving digital threat landscape, ensuring vulnerabilities are identified and addressed promptly.
              </p>
          </div>
        </div>

        <div className="section openS">
          <div className="detailsS">
            <div className="summaryS">Robust Backup and Recovery Protocols</div>
              <p>
                Recognizing the importance of data integrity, Federal Frontier integrates backup protocols for crucial data and configurations. Coupled with disaster recovery strategies, it assures continuity even in unforeseen incidents.
              </p>
          </div>
        </div>
        <div className="section openS">
          <div className="detailsS">
            <div className="summaryS">Versatile Deployment</div>
              <p>
                Flexibility defines Federal Frontier. From traditional data centers to the expansive cloud and the dynamic edge, it seamlessly extends workload clusters, ensuring uniformity in security and performance, irrespective of the deployment environment.
              </p>
          </div>
        </div>
      </div>

      <div className="learn-more">
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="learn-more-button" target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
        <a href="/contact" className="learn-more-button">
          Request a demo
        </a>
      </div>
    </div>
  );
}

export default Security;
