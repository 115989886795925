import React, { useState } from 'react';
import './FrontierEdge.css'; // Import your CSS file

function FrontierEdge() {

    const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="frontier-edge">
      {/* Introduction/Overview Section */}
      <section className="frontier-edge-overview">
        <div className="frontier-edge-header">
          <h1>Federal Frontier FleetEdge</h1>
          <p>Powering Kubernetes, Simplified: Our Federal Frontier software, utilizing the versatile Kubernetes Cluster API (CAPI), reshapes how you deploy Kubernetes stacks. Especially for the optionally rugged NXP Layerscape-based Frontier FleetEdge ARM64 FFE-6S-R node, we ensure a streamlined, replicable, and scalable solution. Single-points-of-failure in your hardware and your software are simply avoided.</p>
        </div>
        <div className="frontier-edge-image-logo">
          <img src="/images/ff_edge_big_2_transparent.png" alt="Frontier Edge Product" />
        </div>
      </section>


      {/* First Div Below (Detailed Explanation and Image) */}
      <div className="frontier-edge-detailed-section">
        {/* Detailed Explanation */}
        <div className="frontier-edge-explanation">
          <h2>FleetEdge</h2>
          <p>FleetEdge is a cutting-edge deployment solution, specifically designed for streamlining the deployment of Kubernetes workload clusters on the edge. This powerful software leverages the Kubernetes Cluster API (CAPI) to revolutionize the way organizations deploy Kubernetes in normal and harsh environments. FleetEdge is especially tailored to provide a seamless and scalable deployment experience for NXP Layerscape-based Frontier FleetEdge ARM64 FFE-6S-[R, RZ] nodes.</p>
        </div>

        {/* Image */}
        <div className="frontier-edge-image">
          <img
            src="/images/ff-diagram.png"
            alt="FKG Kubernetes Grid"
            onClick={openModal}
          />
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the same image at a larger scale */}
            <img
              src="/images/ff-diagram.png"
              alt="FKG Kubernetes Grid"
              className="modal-image"
            />
          </div>
        </div>
      )}

      {/* Second Div Below (Benefits Section) */}
      <div className="frontier-edge-benefits-section">
        <h2>Benefits</h2>
        <div className="frontier-edge-benefits-list">
            <div className="benefit-icon">
              <img src="/images/shield-art.png" alt="Benefit 1" />
              <h3>Enhanced Security and Compliance</h3>
              <p>FleetEdge automates security measures and ensures compliance with government standards, making your deployments more secure and reducing the risk of vulnerabilities.</p>
            </div>
            <div className="benefit-icon">
              <img src="/images/lock-art.png" alt="Benefit 2" />
              <h3>Simplified Management and Maintenance</h3>
              <p>With FleetEdge, managing Kubernetes clusters becomes straightforward, and it offers long-term support, reducing the complexity of ongoing maintenance and keeping your clusters secure and up-to-date.</p>
            </div>
            <div className="benefit-icon">
              <img src="/images/revenue-art.png" alt="Benefit 3" />
              <h3>Efficiency and Scalability</h3>
              <p>FleetEdge simplifies the setup of Kubernetes clusters, saving time and effort. It can quickly adapt to different environments, making it easy to scale as your needs grow.</p>
            </div>
        </div>
      </div>

      {/* First Div Below (Detailed Explanation and Image)
      <div className="frontier-edge-how-it-works">
        <h1>How it works</h1>
        <div className="frontier-edge-how-it-works-content">
          {/* Image
          <div className="frontier-edge-how-it-works-image">
            <img src="/images/sample_flowchart.png" alt="Frontier Edge Product" />
          </div>
          <div className="frontier-edge-how-it-works-text">
            <p>FleetEdge software and hardware is an integrated solution that streamlines the deployment of Kubernetes clusters, particularly optimized for the rugged NXP Layerscape-based Frontier FleetEdge ARM64 FFE-6S-R nodes. It simplifies the entire process into a step-by-step revolution, starting with effortless initial setup and pre-checks for reliability. FleetEdge then offers rapid Kubernetes bootstrapping through a range of user-friendly tools, automating software provisioning, including essential add-ons and compliance-driven requirements. This solution excels in network isolation and micro-segmentation, powered by Calico Enterprise and Netris, ensuring top-tier security. FleetEdge's monitoring capabilities provide real-time insights, while remote connectivity is perfected with Netris SoftGate software, enabling smooth communication across various environments. Life-cycle maintenance becomes easy with 10-year Long-Term Support, encompassing security updates and compliance adherence, making FleetEdge a comprehensive and efficient solution for Kubernetes deployment and management.</p>
          </div>
        </div>
      </div>
      */}

      <div className="fleet-edge-configurations">
        <h1>FleetEdge Hardware Configurations</h1>
        <div className="fleet-edge-configurations-content">
            {/* Detailed Explanation */}
          <div className="fleet-edge-configurations-text">
            <h2 className="configurations-title">FleetEdge Configurations</h2>

            <div className="config-item">
              <h3 className="config-subtitle">1. SoC/Server Count:</h3>
              <p className="config-description">
                Represents the number of Systems on a Chip (SoC) in the hardware module, which is a Computer on a Module (COM) or Server. It ranges from 1 to 6.
              </p>
            </div>

            <div className="config-item">
              <h3 className="config-subtitle">2. Core Count:</h3>
              <p className="config-description">
                Corresponds to the number of processing cores available in the node. As the SoC count increases, the core count also rises. For instance, a node with 1 SoC has 16 cores, and one with 6 SoCs boasts 96 cores.
              </p>
            </div>

            <div className="config-item">
              <h3 className="config-subtitle">3. Memory:</h3>
              <p className="config-description">
                Indicates the RAM (Random Access Memory) capacity of the node. It starts at 64GB for a 1 SoC node and goes up to 384GB for a 6 SoC/Server node.
              </p>
            </div>

            <div className="config-item">
              <h3 className="config-subtitle">4. Configurations:</h3>
              <ul className="config-list">
                <li>
                  <strong>Regular:</strong> Denoted by “FFE-[SoC count]-S”. This is the standard version of the node.
                </li>
                <li>
                  <strong>Rugged:</strong> Denoted by “FFE-[SoC count]-R”. This is a durable or resilient design suitable for challenging environments such as oil rigs, cell towers, and industrial plants.
                </li>
              </ul>
            </div>

            <p className="config-description">
              FleetEdge Hardware is offered in various node configurations. As you move from 1 SoC to 6 SoCs, you get more processing cores and more memory. Additionally, each SoC configuration comes in two types: Regular or Rugged, each tailored for different environmental conditions or use-cases.
            </p>

            <p className="config-description">
              Onboard Trusted Platform Modules (TPM) chips for additional security are available, and GPU options are also available for AI/ML applications on the Edge.
            </p>
          </div>
          {/* Table */}
          <div className="fleet-edge-configurations-table">
            <table>
              <tbody>
                {/* Table Header */}
                <tr>
                  <th>SoC/Server Count</th>
                  <th>Core Count</th>
                  <th>Memory (DDR4)</th>
                  <th>Regular</th>
                  <th>Rugged</th>
                </tr>

                {/* Table Rows */}
                <tr>
                  <td>1</td>
                  <td>16</td>
                  <td>64GB</td>
                  <td>FFE-1S</td>
                  <td>FFE-1S-R</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>32</td>
                  <td>128GB</td>
                  <td>FFE-2S</td>
                  <td>FFE-2S-R</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>48</td>
                  <td>192GB</td>
                  <td>FFE-3S</td>
                  <td>FFE-3S-R</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>64</td>
                  <td>256GB</td>
                  <td>FFE-4S</td>
                  <td>FFE-4S-R</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>80</td>
                  <td>320GB</td>
                  <td>FFE-5S</td>
                  <td>FFE-5S-R</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>96</td>
                  <td>384GB</td>
                  <td>FFE-6S</td>
                  <td>FFE-6S-R</td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="learn-more">
        <a href="http://readthedocs.eupraxia.io/docs/frontier-kubernetes-platform/frontier-kubernetes-platform/" className="FE-learn-more-button" target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
        <a href="images/datasheet.pdf" target="_blank" className="FE-learn-more-button">
            View Datasheet
          </a>
        <a href="/contact" className="FE-learn-more-button">
          Request a Demo
        </a>
        <a href="/contact" className="FE-learn-more-button">
            Contact us for a Quote
        </a>
      </div>



    </div>
  );
}

export default FrontierEdge;
