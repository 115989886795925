import React, { useRef, useEffect, useState } from 'react';
import './Home.css';
import * as THREE from 'three';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductHighlight from './ProductHighlight';
import { Link } from 'react-router-dom';

// Rest of your code



function Home() {
  const containerRef = useRef();
  // Load a noise texture for the diamond-like appearance
  const noiseTexture = new THREE.TextureLoader().load('/images/noise_sphere.png'); // Replace with the actual path
  const lockTexture = new THREE.TextureLoader().load('/images/Eupraxia_X_transparent.png'); // Replace with the actual path
  const lockTexture2 = new THREE.TextureLoader().load('/images/locksteel2.png'); // Replace with the actual path
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  const partnerLogos = [
    '/images/lightbits-logo.png',
    '/images/Netris-logo.png',
    '/images/Tigera-logo.png',
    '/images/Canonical-logo.png',
    '/images/mirantis-logo-horizontal-two-color.png'

  ];
  const partnerLinks = [
    'https://www.lightbitslabs.com/',
    'https://www.netris.io/',
    'https://www.tigera.io/',
    'https://canonical.com/',
    'https://www.mirantis.com/'
  ];
  const numVisibleLogos = 4;
  const intervalTime = 2000;

      const [activeIndexes, setActiveIndexes] = useState([0, 1, 2, 3]);

      useEffect(() => {
        const interval = setInterval(() => {
          setActiveIndexes(prevIndexes => {
            const nextIndexes = prevIndexes.map(index => (index + 1) % partnerLogos.length);
            return nextIndexes;
          });
        }, intervalTime);

        return () => clearInterval(interval);
      }, []);


  useEffect(() => {
    // Create a scene
    const scene = new THREE.Scene();

    // Create a camera
    const camera = new THREE.PerspectiveCamera(70, containerRef.current.offsetWidth / 400, 0.1, 1000);
    camera.position.z = 10;

    // Create a renderer with alpha (transparent) background
    renderer.setSize(containerRef.current.offsetWidth, 400);
    containerRef.current.appendChild(renderer.domElement);

    // Add ambient lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    // Create a "data cloud" animation
    const dataCloud = new THREE.Group();
    const nodes = [];
    const numNodes = 30; // Number of nodes

    for (let i = 0; i < numNodes; i++) {
      const phi = Math.acos(-1 + (2 * i) / numNodes);
      const theta = Math.sqrt(numNodes * Math.PI) * phi;

      const sphereGeometry = new THREE.SphereGeometry(0.2, 16, 16); // Adjust the radius and other parameters
      const sphereMaterial = new THREE.MeshBasicMaterial({ map: noiseTexture }); // Use the noise texture
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

      sphere.position.setFromSphericalCoords(5, phi, theta); // Set position using spherical coordinates
      dataCloud.add(sphere);
      nodes.push(sphere);
    }
    // Create a lock image and add it to the center
    const lockGroup = new THREE.Group();

    const lockImageGeometry = new THREE.PlaneGeometry(6, 6);
    const lockImageMaterial = new THREE.MeshBasicMaterial({ map: lockTexture, transparent: true }); // Enable transparency
    const lockImage = new THREE.Mesh(lockImageGeometry, lockImageMaterial);
    lockGroup.add(lockImage);

    lockGroup.position.set(0, 0, 0);
    scene.add(lockGroup);




    scene.add(dataCloud);

    // Create lines between nodes
    const linesMaterial = new THREE.LineBasicMaterial({ color: 0x33aaff });

    for (let i = 0; i < nodes.length; i++) {
      const nodeA = nodes[i];
      const availableNodes = nodes.filter((_, index) => index !== i);

      if (availableNodes.length > 0) {
        const randomIndexA = Math.floor(Math.random() * availableNodes.length);
        const nodeB = availableNodes[randomIndexA];

        availableNodes.splice(randomIndexA, 1);

        const geometryA = new THREE.BufferGeometry();
        geometryA.setAttribute(
          'position',
          new THREE.BufferAttribute(
            new Float32Array([...nodeA.position.toArray(), ...nodeB.position.toArray()]),
            3
          )
        );
        const lineA = new THREE.Line(geometryA, linesMaterial);
        dataCloud.add(lineA);

        if (availableNodes.length > 0) {
          const randomIndexB = Math.floor(Math.random() * availableNodes.length);
          const nodeC = availableNodes[randomIndexB];

          const geometryB = new THREE.BufferGeometry();
          geometryB.setAttribute(
            'position',
            new THREE.BufferAttribute(
              new Float32Array([...nodeA.position.toArray(), ...nodeC.position.toArray()]),
              3
            )
          );
          const lineB = new THREE.Line(geometryB, linesMaterial);
          dataCloud.add(lineB);
        }
      }
    }


    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      // Rotate the nodes (dataCloud) only
      dataCloud.rotation.x += 0.001;
      dataCloud.rotation.y += 0.001;

      // Randomly decide whether to highlight a node
      if (Math.random() < 0.02) { // Adjust the probability as needed
        // Randomly select a node to animate
        const randomIndex = Math.floor(Math.random() * nodes.length);
        const randomNode = nodes[randomIndex];

        // Apply lighting up effect to the selected node
        if (randomNode) {
          const time = Date.now() * 0.001; // Convert milliseconds to seconds
          const intensity = Math.sin(time * 10) * 0.5 + 0.5; // Varies between 0 and 1

          // Store the original color before highlighting
          if (!randomNode.originalColor) {
            randomNode.originalColor = randomNode.material.color.clone();
          }

          // Define colors
          const blueColor = new THREE.Color(0x33aaff); // Blue color

          // Calculate intermediate color based on intensity
          const color = new THREE.Color();
          color.r = randomNode.originalColor.r + (blueColor.r - randomNode.originalColor.r) * intensity;
          color.g = randomNode.originalColor.g + (blueColor.g - randomNode.originalColor.g) * intensity;
          color.b = randomNode.originalColor.b + (blueColor.b - randomNode.originalColor.b) * intensity;

          randomNode.material.color = color;
          randomNode.material.opacity = intensity;
        }
      }

      renderer.render(scene, camera);
    };

    animate();






    // Clean up
        return () => {
          // Stop the animation loop
          cancelAnimationFrame(animate);

          // Dispose of geometries and materials
          dataCloud.traverse(child => {
            if (child.isMesh) {
              child.geometry.dispose();
              if (child.material.map) {
                child.material.map.dispose();
              }
              child.material.dispose();
            }
          });

          // Remove objects from the scene
          while (scene.children.length > 0) {
            scene.remove(scene.children[0]);
          }

          // Clean up the renderer
          renderer.dispose();
        };
      }, []);
    function getPartnerLink(index) {
      // Replace this with the actual URLs for your partners
      return partnerLinks[index];
    }

  return (


    <section className="home-section">
    {/*<video className="home-background-video" src='/images/background.mp4' loop muted autoPlay></video>*/}
    <video className="home-background-video" src='/images/background.mp4' loop muted autoPlay></video>
      <div className="home-content">
        <div className="home-text">
          <h1>Cloud to Data Center to Edge</h1>
          <p>It All Starts Here</p>
          <Link to="/products">
            <button className="explore-button">Learn More</button>
          </Link>
        </div>
        <div className="home-3d-container" ref={containerRef}></div>
      </div>
      <div className="our-partners-section">
          <h2 className="our-partners-title">Our Partners</h2>
          <Slider
            className="partner-logos"
            infinite={true}
            autoplay={true}
            autoplaySpeed={intervalTime}
            slidesToShow={window.innerWidth <= 768 ? 3 : 3} // Display 3 images on mobile screens, 6 images on larger screens
            slidesToScroll={1}
          >
            {partnerLogos.map((logo, index) => (
              <div key={index}>
                <a href={getPartnerLink(index)} target="_blank" rel="noopener noreferrer">
                  <img src={logo} alt={`Partner Logo ${index}`} className="partner-logo" />
                </a>
              </div>
            ))}
          </Slider>
      </div>
      <div className="product-highlight-section">
        <ProductHighlight />
      </div>

    </section>
  );
}

export default Home;