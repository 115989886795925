import React, { useState } from 'react';
import './WhyCAPI.css'; // Import your CSS file for styling

function WhyCAPI() {
    const [isModalOpen, setIsModalOpen] = useState(false);

      // Function to open the modal
      const openModal = () => {
        setIsModalOpen(true);
      };

      // Function to close the modal
      const closeModal = () => {
        setIsModalOpen(false);
      };
  return (
    <div className="why-capi">

      {/* Section 1: Introduction to CAPI */}
      <div className="capi-section intro-section">
        <h2 className="capi-section-intro-title">What is Cluster API and Why is it Useful?</h2>
        <p className="capi-section-intro-text">
          Cluster API (CAPI) is a powerful tool for managing the lifecycle of Kubernetes workload clusters. It provides an abstraction layer that simplifies the provisioning and management of clusters across various platforms.
        </p>
        <p className="capi-section-intro-text">
          CAPI allows us to create a standardized and consistent experience when deploying Kubernetes clusters, regardless of the underlying infrastructure.
        </p>
      </div>

      {/* Section 2: How We Use CAPI */}
      <div className="capi-section usage-section">
        <h2 className="capi-section-usage-title">How We Use Cluster API</h2>
        <div className="usage-content">
          <div className="usage-image">
            <img
              src="images/capi-architecture.jpg"
              alt="Usage Image"
              onClick={openModal}
            />
          </div>
          <div className="usage-text">
            <p>
              At Eupraxia Labs, we leverage Cluster API to streamline the deployment of Kubernetes clusters. We create a Cluster API management cluster, known as the Federal Frontier Management Cluster (FMC), by installing Cluster API on an existing Kubernetes cluster using 'clusterctl'.
            </p>
            <p>
              We integrate Kubernetes CAPI providers like K8s, Canonical Microk8s, Mirantis K0s, and K3s into FMC, enabling them to run on various infrastructures, including bare metal clouds (BMC), virtual machines (VM), on-premises, in the cloud, or on the edge.
            </p>
          </div>

        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the same image at a larger scale */}
            <img
              src="images/capi-architecture.jpg"
              alt="Usage Image"
              className="modal-image"
            />
          </div>
        </div>
      )}

      {/* Section 3: Benefits of CAPI */}
      <div className="capi-section benefits-section">
        <h2 className="capi-section-benefits-title">Benefits of Cluster API</h2>
        <p className="capi-section-benefits-text">
          The adoption of Cluster API (CAPI) within an organization brings numerous benefits. Some key advantages include:
        </p>
        <ul className="capi-benefits-list">
          <li>Standardization of cluster provisioning.</li>
          <li>Abstraction of platform-specific requirements, such as security, networking and storage.</li>
          <li>Consistent experience across different target infrastructures.</li>
          <li>Significantly reduced time-to-market or time-to-mission.</li>
        </ul>
      </div>
    </div>
  );
}

export default WhyCAPI;
